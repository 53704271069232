<template>
  <div class="w-100 bg-neutral-light flex-2">
    <div class="section">
      <div
        class="wd-100p wd-md-65p p-3 p-md-5 py-5 h-100p d-flex flex-column align-items-center align-items-md-start justify-content-center"
      >
        <h3 class="text-neutral-dark">Who We Are</h3>
        <h5 class="tex-neutral-dark">
          The technology experts of the spaces where you work, play, and sleep.
          Our team, with over 50 years of combined experience, has the knowledge
          and skills to make your spaces suit you. We offer end-to-end home and
          office technology advancements that make the space function
          intuitively and reliably. Most importantly, Space 7 creates spaces
          where you want to be.
        </h5>
      </div>

      <img
        :src="require('../assets/images/about/lighting.png')"
        class="wd-100p wd-md-35p img-cover"
      />
    </div>
    <div class="section bg-neutral-dark">
      <img
        :src="require('../assets/images/about/lighting.png')"
        class="wd-100p wd-md-35p img-cover order-1 order-md-0"
      />
      <div
        class="wd-100p wd-md-65p p-3 p-md-5 py-5 h-100p d-flex flex-column align-items-center align-items-md-start justify-content-center order-0 order-md-1"
      >
        <h3 class="text-neutral-light">What we do</h3>
        <h5 class="text-neutral-light">
          Our digital platform can guide you through the journey of designing
          and customizing your space in a few simple steps. Whether you dream of
          a home with music and entertainment, an office with complete climate
          and light controls, a vacation home with a safety and security system,
          or a space with everything, we can make your dreams come true.
        </h5>
      </div>
    </div>
    <div class="section">
      <div
        class="wd-100p wd-md-65p p-3 p-md-5 py-5 h-100p d-flex flex-column align-items-center align-items-md-start justify-content-center"
      >
        <h3 class="text-neutral-dark">How we do it</h3>
        <h5 class="tex-neutral-dark">
          Our digital platform will guide you through the entire journey of
          creating your perfect space. Once you have chosen your design, we will
          deliver, install, and personalize it hassle-free. Once everything is
          in place, we offer various options of after-sales support, should you
          choose.
        </h5>
      </div>

      <img
        :src="require('../assets/images/about/lighting.png')"
        class="wd-100p wd-md-35p img-cover"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>

<style scoped lang="scss">
.section {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    height: 60vh;
  }
}
</style>
